import React from 'react';
import LandingPage from './LandingPage';
import { Button, Layout, Menu } from 'antd';
import { Link, Switch, Route } from 'react-router-dom';
import PropertyManagement from './PropertyManagement';
import AvailableRentals from './Rentals';
const { SubMenu } = Menu;

const { Header, Footer, Content } = Layout;

class LandingLayout extends React.Component {
	render() {
		return (
			<Layout id='steadyPage' className='d-inline'>
				<Header id='steadyHeader'>
					<div className='d-inline steadyimg'>
						<Link to='/'>
							<img
								alt='Steady Property Management'
								src={require('../icons/steady.png')}
								width='86'
								height='63'
							/>
						</Link>
					</div>
					<div className='floatRight'>
						<Button type='primary' size='large' className='PMloginBtnB'>
							<Link className='' id='clientLogin' to='/Login'>
								Client Login
							</Link>
						</Button>
					</div>
					<Menu mode='horizontal'>
						<Menu.Item key='1'>
							<Link to='/propertyManagement'>Property Management</Link>
						</Menu.Item>
						<SubMenu key='sub1' title='Available Rentals'>
							<Menu.Item key='5'>
								<Link to='/rentals'>Long Term Rentals</Link>
							</Menu.Item>
							<Menu.Item key='6'>
								<a href='https://www.airbnb.ca/users/217430588/listings'>
									Short Term Rentals
								</a>
							</Menu.Item>
						</SubMenu>
						<Menu.Item key='4'>
							<a href='mailto: daniel@steadypropertymanagement.com'>
								Contact Us
							</a>
						</Menu.Item>
					</Menu>
				</Header>
				<Content>
					<Switch>
						<Route exact path={'/'} key={'login'} component={LandingPage} />
						<Route
							exact
							path={'/propertyManagement'}
							key={'propertyManagement'}
							component={PropertyManagement}
						/>
						<Route
							exact
							path={'/rentals'}
							key={'rentals'}
							component={AvailableRentals}
						/>
					</Switch>
				</Content>
				<Footer id='steadyFooter'>
					<div className='g-text-sm' id='footer'>
						<div className='mt-2' id='footer-clients'>
							<h6>
								{' '}
								<u>Our Services</u>
							</h6>
							<a href='/login'>
								<p>User Login</p>
							</a>
							<a href='/propertyManagement'>
								<p>Property Management</p>
							</a>
							<a href='/rentals'>
								<p>Property Rental</p>
							</a>
							<p>Request Maintenance</p>
						</div>
						<div className='mt-2' id='footer-company'>
							<h6>
								{' '}
								<u>Our Company</u>
							</h6>
							<p>About us</p>
							<p>Our Team</p>
							<p>Careers</p>
						</div>
						<div className='mt-2' id='footer-other'>
							<h6>
								{' '}
								<u>Social Media</u>
							</h6>
							<a href='https://www.facebook.com/SteadyPropertyManagement/'>
								<img
									alt='facebook'
									width='24'
									height='24'
									src='https://img.icons8.com/fluent/48/000000/facebook-new.png'
								/>
								&nbsp;Steady Property Management
							</a>
						</div>
					</div>
					<div id='copyrightclaim' className='g-text-sm'>
						<p className='copyright'>
							© 2016 by Steady Property Management Inc.
						</p>
					</div>
				</Footer>
			</Layout>
		);
	}
}

export default LandingLayout;
